<template>
  <layout-template>
    <template slot="main">
      <list-template>
        <template v-slot:header>
          <div class="flex flex-between-center container header">
            <div>
              <div class="nickname">{{userInfo.name || ''}}</div>
              <div class="department">{{tenantName || ''}}</div>
            </div>
            <div class="logout flex flex-center" @click="onLogout">注销登录</div>
          </div>
        </template>
        <base-scroll load-data-url="/h5/place/pageWithoutChildren" :query="query" @choose="onCheck">
          <template v-slot="{rows}">
              <div class="block flex flex-between-center" v-for="(row,index) in rows" :key="row.id" :data-index="index">
                <div>{{row.name}}</div>
                <img src="@/assets/arrow_right.svg" class="icon"/>
              </div>
          </template>
        </base-scroll>
      </list-template>
    </template>
  </layout-template>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseScroll from '@/components/base-scroll'
// const query = Object.freeze({ level: 0, tenantId: tenantId })
const preventBack = function (e) {
  console.log(e)
  e.preventDefault()
  return false
}
export default {
  name: 'home',
  components: { BaseScroll },
  computed: {
    ...mapGetters(['userInfo', 'tenantName', 'tenantId'])
  },
  data () {
    return {
      query: { level: 0 }
    }
  },
  beforeRouteEnter (to, from, next) {
    // window.history.replaceState('', '', '#')
    next()
  },
  created () {
    this.query.tenantId = this.tenantId
  },
  mounted () {
    document.body.scrollIntoView(true)
    // window.addEventListener('popstate', preventBack)
  },
  destroyed () {
    // window.removeEventListener('popstate', preventBack)
  },
  methods: {
    onCheck (row) {
      localStorage.setItem('name', row.name)
      this.$router.push({ name: 'process', params: { id: row.id } })
    },
    onLogout () {
      this.$store.dispatch('Logout').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
    }
  }
}
</script>
<style scoped lang="stylus">
  .container
    height 92%
  .header
    padding 0 32rpx
  .nickname
    font(32,48,#fff)
    margin-bottom 8rpx
  .department
    font(24,40,rgba(255,255,255,.65))
    ellipse(500)
  .logout
    width:128rpx;
    height:48rpx;
    background:rgba(255,255,255,0.35);
    border-radius:24rpx;
    font(24,40,#fff)
  .block
    background #fff
    border-radius 20rpx
    margin 40rpx 32rpx
    height 112rpx
    min-height 20px
    padding 0 32rpx
    font(28,44,$text)
</style>
